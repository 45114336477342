/*
Google Analytics events
<https://support.google.com/analytics/answer/1033068?hl=en#zippy=%2Cin-this-article>

Click tracking works simply by adding `data-track='category:action:label?:value'` to any element.

Categories:

- `cta`: buttons
- `social`: out social media
- `external`: external profiles and links
- `footer`: nav links in footer
- `nav`: nav links in navigation
- `settings`: theme

Actions:
- always a verb

Labels:
- where the click happened, e.g., `from-app-promo`

Values:
- currently not used

To conveniently see what element triggers what GA event, add `.debug-tracking` to the body.

From Console:

  `document.body.classList.add('debug-tracking')`


*/

const EVENT_ATTR_SEPARATOR = ':'


export const makeTrackAttr = (...attrs: string[]) =>
  // Build an attr string with some basic sanitation of the attrs.
  attrs.map(
    attr => attr
      .replace('https://', '')
      .replace(':', '-')
      .replace('\n', '⏎ ')
  ).join(EVENT_ATTR_SEPARATOR)


export const trackClick = (event: MouseEvent) => {
  // Global click handler
  if (!event.target) {
    return
  }
  const target = event.target as HTMLElement
  const tracking = target.closest('[data-track]') as HTMLElement
  if (tracking) {
    const attrsString = tracking.dataset?.track
    if (attrsString) {
      // eslint-disable-next-line no-console
      // console.log('track:', attrsString)
      const attrs = attrsString.split(EVENT_ATTR_SEPARATOR)
      trackGaEvent(attrs[0], attrs[1], attrs[2], attrs[3])
    }
  }
}


export const trackGaEvent = (category: string, action: string, label = '', value = '') => {
  window.gtag?.('event', action, {
    event_category: category,
    event_label: label,
    value: value,
  })
}


declare global {
  interface Window {
    // To make `window.gtag` work with Typescript
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    gtag: any
  }
}
