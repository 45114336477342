import Image from 'next/legacy/image'

import {GITHUB_ORG_URL, PAGE_ROUTES, TWITTER_COMPANY_PROFILE_URL} from '~/constants'
import Button from '~/components/Button/Button'
import Text from '~/components/Text/Text'
import NewsletterForm from '~/components/NewsletterForm/NewsletterForm'

import styles from './Community.module.sass'

const shapeAltText = 'Open Source. Open hearted. Open minded.'

interface Props {
  orgRepoStargazers: number | null
  showOpenSource?: boolean
}

const Community: React.FC<Props> = ({orgRepoStargazers, showOpenSource = true}) => {
  return (
    <div className={styles.community}>
      <div className='site-container site-grid gap-y-[84px]'>
        <div
          className='flex items-center justify-center col-span-2 md:justify-start md:col-start-2 md:col-span-3 lg:hidden'>
          {showOpenSource &&
            <Image
              src='/Images/community-shape-mobile.svg'
              layout='fixed'
              width={220}
              height={238}
              alt={shapeAltText}
              className={styles.shape}
            />
          }
        </div>

        <div className='hidden lg:flex lg:items-center lg:justify-center lg:col-span-4 lg:col-start-2'>
          {showOpenSource &&
            <Image
              src='/Images/community-shape.svg'
              layout='fixed'
              width={255}
              height={277}
              alt={shapeAltText}
              className={styles.shape}
            />
          }
        </div>

        <div className='col-span-2 gap-y-[50px] md:col-span-3 lg:col-span-4 lg:col-start-7'>
          <Text fontSize={[20, 20, 25]} className={styles.heading}>
            Join the community
          </Text>

          <div className={styles.buttons}>
            <Button
              forceDarkMode
              variant='counter'
              count={orgRepoStargazers}
              countTitle='Overall star count across our public GitHub repositories'
              icon='github'
              link={GITHUB_ORG_URL}
              external
              className='w-full'
              data-track='social:star-on-github:from-community'
            >
              Star on GitHub
            </Button>

            <div className={styles.socialButtons}>
              <Button
                forceDarkMode
                variant='secondary'
                icon='discord'
                link={PAGE_ROUTES.DISCORD}
                external
                className='w-full'
                data-track='social:go-to-discord:from-community'
              >
                Discord
              </Button>

              <Button
                forceDarkMode
                link={TWITTER_COMPANY_PROFILE_URL}
                external
                icon='twitter'
                className='w-full'
                data-track='social:go-to-twitter:from-community'
              >
                Twitter
              </Button>
            </div>
          </div>

          <Text fontSize={[20, 20, 25]} className={styles.heading}>
            Join the occasional newsletter
          </Text>

          <NewsletterForm variant='community' trackLabel='from-community' data-testid='community-newsletter-form'/>
        </div>
      </div>
    </div>
  )
}

export default Community
