import Head from 'next/head'
import dynamic from 'next/dynamic'
import { ReactNode, useEffect } from 'react'

import { Favicons } from '~/components/Favicons'
import { TWITTER_COMPANY_USERNAME } from '~/constants'
import { FetchedLayoutProps } from '~/lib/layout'
import {
  DEFAULT_DESCRIPTION,
  DEFAULT_SHARE_IMAGE,
  DEFAULT_TITLE_TEMPLATE,
  ensureShareImageURLAbsolute,
  makeFullTitle,
} from '~/lib/seo'
import { trackClick } from '~/lib/ga-events'
import { Announcement } from '~/components/Announcement/Announcement'
import { MetaFeedLinks } from '~/lib/feed'

// Optimization aiming to reduce first load JS.
// Depending on page, it decreases initial JS bundles from 4kB to almost 20kB.
// <https://github.com/httpie/pie-web/pull/52#issuecomment-953584179>
const Navigation = dynamic(() => import('~/sections/Navigation/Navigation'))
const Footer = dynamic(() => import('~/sections/Footer/Footer'))

interface Props extends FetchedLayoutProps {
  children: ReactNode
  hideFooter?: boolean
  title?: string | string[]
  titleTemplate?: string | null
  description?: string
  shareDescription?: string
  shareImage?: string
}

export const Layout: React.FC<Props> = ({
  children,
  hideFooter = false,
  title,
  titleTemplate = DEFAULT_TITLE_TEMPLATE,
  description = DEFAULT_DESCRIPTION,
  shareDescription,
  shareImage = DEFAULT_SHARE_IMAGE,
  jobsCount,
  blogCount,
}) => {
  shareImage = ensureShareImageURLAbsolute(shareImage)
  title = makeFullTitle(title, titleTemplate)
  useEffect(() => {
    document.documentElement.addEventListener('click', trackClick)
    return () => document.documentElement.removeEventListener('click', trackClick)
  }, [])
  return (
    <>
      <Head>
        <meta key='charset' charSet='utf-8' />
        <meta key='viewport' name='viewport' content='width=device-width, initial-scale=1, viewport-fit=cover' />

        {/* SEO */}
        <title>{title}</title>
        <meta itemProp='name' content={title} />
        <meta name='description' content={description} />
        <meta property='og:title' content={title} />
        <meta property='og:site_name' content={title} />
        <meta property='og:description' content={shareDescription || description} />
        <meta property='og:type' content='website' />
        <meta property='og:image' content={shareImage} />
        <meta name='twitter:card' content='summary_large_image' />
        <meta name='twitter:site' content={`@${TWITTER_COMPANY_USERNAME}`} />
        <meta name='twitter:title' content={title} />
        <meta name='twitter:image' content={shareImage} />
        <meta name='twitter:description' content={shareDescription || description} />
        {/* #SEO */}

        <MetaFeedLinks />

        {/* Preload FK Screamer */}
        <link
          rel='preload'
          as='font'
          href='/Brand/Fonts/FKScreamer/FKScreamer-Bold.woff2'
          type='font/woff2'
          crossOrigin='anonymous' // <https://developer.mozilla.org/en-US/docs/Web/HTML/Link_types/preload#cors-enabled_fetches>
        />
      </Head>
      <Favicons /> {/* keep after </Head> */}
      <Announcement />
      <Navigation jobsCount={jobsCount} blogCount={blogCount} />
      {children}
      {!hideFooter && <Footer jobsCount={jobsCount} />}
    </>
  )
}
