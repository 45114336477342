import Head from 'next/head'
import React from 'react'

import tailwindConfig from '~/tailwind.config.js'

import {Theme, useTheme} from '../lib/ThemeProvider'


export const Favicons = () => {
  const {systemTheme} = useTheme()
  const prefix = `/Brand/Favicons/${systemTheme == Theme.DARK ? 'White' : 'Black'}`
  const themeColor = systemTheme == Theme.DARK ? tailwindConfig.theme.colors.black : tailwindConfig.theme.colors.white
  return <Head>
    <link rel='apple-touch-icon' sizes='57x57' href={`${prefix}/apple-icon-57x57.png`}/>
    <link rel='apple-touch-icon' sizes='60x60' href={`${prefix}/apple-icon-60x60.png`}/>
    <link rel='apple-touch-icon' sizes='72x72' href={`${prefix}/apple-icon-72x72.png`}/>
    <link rel='apple-touch-icon' sizes='76x76' href={`${prefix}/apple-icon-76x76.png`}/>
    <link rel='apple-touch-icon' sizes='114x114' href={`${prefix}/apple-icon-114x114.png`}/>
    <link rel='apple-touch-icon' sizes='120x120' href={`${prefix}/apple-icon-120x120.png`}/>
    <link rel='apple-touch-icon' sizes='144x144' href={`${prefix}/apple-icon-144x144.png`}/>
    <link rel='apple-touch-icon' sizes='152x152' href={`${prefix}/apple-icon-152x152.png`}/>
    <link rel='apple-touch-icon' sizes='180x180' href={`${prefix}/apple-icon-180x180.png`}/>
    <link rel='icon' type='image/png' sizes='192x192' href={`${prefix}/android-icon-192x192.png`}/>
    <link rel='icon' type='image/png' sizes='32x32' href={`${prefix}/favicon-32x32.png`}/>
    <link rel='icon' type='image/png' sizes='96x96' href={`${prefix}/favicon-96x96.png`}/>
    <link rel='icon' type='image/png' sizes='16x16' href={`${prefix}/favicon-16x16.png`}/>
    <meta name='msapplication-TileImage' content={`${prefix}/ms-icon-144x144.png`}/>
    <meta name='msapplication-TileColor' content={themeColor}/>
    <meta name='theme-color' content={themeColor}/>
    <meta name='apple-mobile-web-app-status-bar-style' content='black-translucent' />
  </Head>
}
