import { MouseEvent, useState } from 'react'
import { useIsomorphicLayoutEffect } from 'react-spring'
import { useRouter } from 'next/router'
import clsx from 'clsx'

import { PAGE_ROUTES } from '~/constants'
import { CONTENT, STORAGE_KEY, TRACK_CLICK, TRACK_CLOSE, URL } from '~/components/Announcement/content'

import styles from './Announcement.module.sass'


const IS_ENABLED = false


/* </Announcement config> */

// For anchors on docs to have proper offset
export const ANNOUNCEMENT_HEIGHT = 48
export const Announcement = () => {
  const isDocPage = useRouter().pathname.startsWith(PAGE_ROUTES.DOCS_CLI)
  const isManuallyHidden = typeof window == 'undefined' ? false : Boolean(localStorage[STORAGE_KEY])
  // Non-large docs page cannot handle the announcement because of the fixed header.
  const onlyShowForLargeScreens = isDocPage
  /*
  By default, the announcement is hidden to avoid server/client markup mismatch.
  */
  const [hidden, setHidden] = useState(true)
  const hide = (e: MouseEvent) => {
    localStorage[STORAGE_KEY] = 1
    setHidden(true)
    e.preventDefault()
  }

  /*
  Apply useIsomorphicLayoutEffect to:
  - Avoid FOUC and apply correct state immediately at the first render.
  - Avoid server/client hydration mismatch by calling `useEffect` on server and `useLayoutEffect` on client.
  */
  useIsomorphicLayoutEffect(() => {
    setHidden(isManuallyHidden)
  }, [isManuallyHidden])

  if (!IS_ENABLED || hidden) {
    return null
  }

  return (
    <a
      href={URL}
      title='Read announcement'
      data-track={TRACK_CLICK}
      className={clsx({ 'hidden lg:block': onlyShowForLargeScreens })}
    >
      <div className={styles.announcement}>
        {CONTENT}
        <span
          className={styles.close}
          role='button'
          onClick={hide}
          title='Close announcement'
          data-track={TRACK_CLOSE}
          tabIndex={0}
        >
          ⨉
        </span>
      </div>
    </a>
  )
}
