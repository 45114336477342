/**
 * Utilities for the blog feeds.
 *
 * The server-side portion is in pages/api/feed
 *
 */
import { HOMEPAGE_FULL_URL } from '~/constants'


export enum FeedFormat {
  ATOM = 'atom',
  RSS = 'rss',
  JSON = 'json'
}

export const FEED_MIME = {
  [FeedFormat.ATOM]: 'application/atom+xml',
  [FeedFormat.RSS]: 'application/rss+xml',
  [FeedFormat.JSON]: 'application/feed+json',
}

export const MetaFeedLinks = () =>
  <>
    {Object.values(FeedFormat).map((format, i) => (
      <link
        key={i}
        rel='alternate'
        type={FEED_MIME[format]}
        href={getFeedUrl(format)}
      />
    ))}
  </>

const getFeedUrl = (format: FeedFormat, absolute = true) => {
  const prefix = absolute ? HOMEPAGE_FULL_URL : ''
  return `${prefix}/api/feed/${format}`
}
