import { HOMEPAGE_FULL_URL } from '~/constants'

export const DEFAULT_TITLE_TEMPLATE = '%s – HTTPie'
export const DEFAULT_FULL_TITLE = 'HTTPie – API testing client that flows with you'
export const DEFAULT_DESCRIPTION = 'Making APIs simple and intuitive for those building the tools of our time.'
export const DEFAULT_SHARE_IMAGE = '/Images/Share/default.png'

export const BLOG_TITLE_TEMPLATE = '%s – HTTPie blog'

export const DESKTOP_SHARE_IMAGE = '/Images/Share/desktop.png'
export const CLI_SHARE_IMAGE = '/Images/Share/cli.png'
export const CLI_DESCRIPTION =
  'JSON, syntax highlighting, sessions, wget-like downloads, plugins, and more.'

// 'example', 'test %s',                          -> 'test example'
// ['more complicated', 'example'], '%s test %s'  -> 'more complicated test example'
// 'example', '%s test %s'                        -> 'example test example'
export const makeFullTitle = (title: string | string[] = DEFAULT_FULL_TITLE, template: string | null = ''): string => {
  let titleFromTemplate = template
  if (title != DEFAULT_FULL_TITLE && titleFromTemplate) {
    for (const literal of typeof title === 'string' ? [title] : title) {
      title = titleFromTemplate.replace('%s', literal)
      titleFromTemplate = title
    }
  }
  return typeof title === 'string' ? title : DEFAULT_FULL_TITLE
}

export const ensureShareImageURLAbsolute = (url: string) => {
  // <https://vercel.com/docs/projects/environment-variables#system-environment-variables>
  if (!url) {
    return ''
  }
  const deploymentDomain = process.env.VERCEL_URL
  const isExternalImage = url.startsWith('https://') || url.startsWith('http://')
  if (deploymentDomain && !isExternalImage) {
    if (process.env.VERCEL_ENV == 'production') {
      // The deployment domain changes on every commit, so we manually use the HP URL for prod.
      url = HOMEPAGE_FULL_URL + url
    } else {
      // This will give us working Twitter share cards on preview URLs for testing.
      url = `https://${deploymentDomain}${url}`
    }
  }
  return url
}
