// The announcement banner content and attributes are defined here.

/**
 * A unique, human recognizable ID if the thing we’re promoting.
 * Change every time we promote something new.
 * NOTE: it will get a new storage key, etc., if the ID changes, so it will get unique tracking and reopens.
 */
const ID = 'ai2'

/**
 * The banner link URL
 */
export const URL = '/ai'

/**
 * The banner content
 */
export const CONTENT = <>
  HTTPie AI 2.0 with Chat is coming soon{' '}
  <span className='hidden md:inline'> — join the waiting list</span> 🎉
</>


/**
 * Storage key for the banner dismissal and tracking args based on the ID.
 */
export const STORAGE_KEY = `pie-web:hide-${ID}-announce`
export const TRACK_CLICK = `cta:${ID}:announcement-read`
export const TRACK_CLOSE = `cta:${ID}:announcement-close`
